import DataOptions from "data/locales/id/tabs.json";

export const TabsCustom: React.FC<{
  data?: any;
  selectedItem?: any;
  onSelectItem?: (e: any) => void;
  id?: string; // Attribute that you have created on tabs.json
  useMinWidth?: boolean;
}> = ({ data, selectedItem, onSelectItem, id, useMinWidth }) => {
  const optionContent = id ? DataOptions[id] : data;

  return (
    <div className="flex justify-around items-end gap-[16px] lg:gap-0 lg:items-center w-full">
      {optionContent &&
        optionContent.map((item: any) => {
          const isSelected = [item.name, item.id].includes(selectedItem);

          return (
            <div
              key={item.name}
              className={`cursor-pointer pb-2 ${
                useMinWidth ? "min-w-[224px] lg:min-w-[160px]" : "w-full"
              }`}
              onClick={(e) => {
                e.preventDefault();
                onSelectItem(item);
              }}
            >
              <div
                className={`text-b2 mb-4 lg:mb-10 text-center font-medium ${
                  isSelected ? "text-purple-600" : "text-neutral-600"
                }`}
              >
                {item.name}
              </div>

              <div className="relative mt-2 h-[3px] w-full bg-purple-10 rounded-xl overflow-hidden">
                <div
                  className={`absolute top-0 left-0 h-full rounded-xl transition-all duration-500 ${
                    isSelected
                      ? "animate-fill-purple-50"
                      : "animate-fill-purple-10 animate-exit"
                  }`}
                ></div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
